// ------home page
import { Component, Vue } from "vue-property-decorator";
import product1 from '@/assets/images/product1.png';
import product2 from '@/assets/images/product2.png';
import product3 from '@/assets/images/product3.png';
import product4 from '@/assets/images/product4.png';
import product5 from '@/assets/images/product5.png';
import prot1 from '@/assets/images/prot1.png';
import prot2 from '@/assets/images/prot2.png';
import prot3 from '@/assets/images/prot3.png';
import prot4 from '@/assets/images/prot4.png';
import prot5 from '@/assets/images/prot5.png';
import prot6 from '@/assets/images/prot6.png';
import prot7 from '@/assets/images/prot7.png';
import prot8 from '@/assets/images/prot8.png';
import prot9 from '@/assets/images/prot9.png';
import prot10 from '@/assets/images/prot10.png';
import prot11 from '@/assets/images/prot11.png';
import prot12 from '@/assets/images/prot12.png';
import prot13 from '@/assets/images/prot13.png';
import ToFooter from "@/components/footer/footer.vue";
import Header from "@/components/header/header.vue";

@Component({
  components: {
      ToFooter,
      Header,
  }
})
export default class Product extends Vue {
    private toptype:number=3;
    private topnumber:number=1;
    private page:number=1;
    private pagesize:number=5;
    private pagetotle:number=13;
    private productlist=[
        {
            image:prot1,
            title:'联维智慧园区管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot2,
            title:'联维商业物流仓储管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot3,
            title:'联维制造业物流仓储管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot4,
            title:'联维智慧物流仓储管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot5,
            title:'联维大数据分析平台系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },
        {
            image:prot6,
            title:'联维供应链平台管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot7,
            title:'联维工程管理信息平台系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot8,
            title:'联维工程管理信息平台系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot9,
            title:'联维云通讯平台系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot10,
            title:'联维购物中心管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },
        {
            image:prot11,
            title:'联维商业分销管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot12,
            title:'联维服装分销管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        },{
            image:prot13,
            title:'联维黄金珠宝分销管理系统',
            contect:'具体产品解决方案请联系青岛联维软件科技有限公司产品部咨询'
        }
    ];
    private productlist1=[
        {
            id:1,
            image:product1,
            title:'中石化智慧园区及智慧物流仓储平台',
            contect:'中国石化销售企业自2008年起涉足非油品领域，目前，已在全国加油站内开设易捷便利店2.3万家，成为便利店连锁行业内开店最多的品牌。山东省现有易捷便利店2000余家，2014年销售额已达17亿余元，全省在济南、青岛、潍坊、烟台、曲阜建立了5个物流中心仓，为易捷便利店提供配套的物流配送服务…'
        },{
            id:2,
            image:product2,
            title:'山东中石化供应链平台系统',
            contect:'2019年初，本着加快山东公司非油品业务信息系统发展目的，联维软件为山东中石化搭建供应链平台系统，并于2019年9月上线试运行。平台系统以WMS物流仓储管理平台为基础，整合上游供应商、物流中央仓、下游门店站点资源和信息，实现业务方面的物流仓智能补货、门店智能订货、供应商协同辅助…'
        },
        {
            id:3,
            image:product3,
            title:'中节能万润精细化工物流仓储平台',
            contect:'中节能万润股份是国务院直属中节能集团下属的，从事液晶材料和其他精细化工产品研究、开发、生产和销售的高新技术上市企业，其产品具有技术含量高、生产难度大、高附加值等特点，企业有自营进出口权。已成为国内屈指可数的液晶材料生产厂家，形成了以液晶材料产品为主，光电化学产品、医药…'
        },{
            id:4,
            image:product4,
            title:'中节能万润C10园区智慧仓库项目',
            contect:'2019年6月，中节能C10新园区智慧仓库建成投入使用，新仓库配备了AGV机器人、红外感应数据采集设备、高速高清晰照相机、机械臂、穿梭提升机、电子称等现代化设备，规划设计为国内领先的无人值守智慧仓库…' +
                '该项目由联维软件提供WMS系统指挥调度各智能设备…'
        },{
            id:5,
            image:product5,
            title:'其他案例',
            contect:'青岛联维软件近年来，陆续为多家知名合作企业打造业务系统及信息平台应用，包括爱立信云通信平台、海信渠道管理系统、青岛市农委土地流转信息平台、秋林颐和黄金珠宝分销系统、天津恒星广场购物中心系统等…'
        },
    ];
    private productlist2=[
        {
            image:product2,
            title:'山东中石化供应链平台系统',
            contect:'2019年初，本着加快山东公司非油品业务信息系统发展目的，联维软件为山东中石化搭建供应链平台系统，并于2019年9月上线试运行。平台系统以WMS物流仓储管理平台为基础，整合上游供应商、物流中央仓、下游门店站点资源和信息，实现业务方面的物流仓智能补货、门店智能订货、供应商协同辅助…'
        },
        {
            image:product1,
            title:'中石化智慧园区及智慧物流仓储平台',
            contect:'中国石化销售企业自2008年起涉足非油品领域，目前，已在全国加油站内开设易捷便利店2.3万家，成为便利店连锁行业内开店最多的品牌。山东省现有易捷便利店2000余家，2014年销售额已达17亿余元，全省在济南、青岛、潍坊、烟台、曲阜建立了5个物流中心仓，为易捷便利店提供配套的物流配送服务…'
        },
        {
            image:product2,
            title:'山东中石化供应链平台系统',
            contect:'2019年初，本着加快山东公司非油品业务信息系统发展目的，联维软件为山东中石化搭建供应链平台系统，并于2019年9月上线试运行。平台系统以WMS物流仓储管理平台为基础，整合上游供应商、物流中央仓、下游门店站点资源和信息，实现业务方面的物流仓智能补货、门店智能订货、供应商协同辅助…'
        },{
            image:product3,
            title:'中节能万润精细化工物流仓储平台',
            contect:'中节能万润股份是国务院直属中节能集团下属的，从事液晶材料和其他精细化工产品研究、开发、生产和销售的高新技术上市企业，其产品具有技术含量高、生产难度大、高附加值等特点，企业有自营进出口权。已成为国内屈指可数的液晶材料生产厂家，形成了以液晶材料产品为主，光电化学产品、医药…'
        },
    ];
    private productlist3=[
        {
            image:product4,
            title:'中节能万润C10园区智慧仓库项目',
            contect:'2019年6月，中节能C10新园区智慧仓库建成投入使用，新仓库配备了AGV机器人、红外感应数据采集设备、高速高清晰照相机、机械臂、穿梭提升机、电子称等现代化设备，规划设计为国内领先的无人值守智慧仓库。\n' +
                '该项目由联维软件提供WMS系统指挥调度各智能设备…'
        },
        {
            image:product2,
            title:'山东中石化供应链平台系统',
            contect:'2019年初，本着加快山东公司非油品业务信息系统发展目的，联维软件为山东中石化搭建供应链平台系统，并于2019年9月上线试运行。平台系统以WMS物流仓储管理平台为基础，整合上游供应商、物流中央仓、下游门店站点资源和信息，实现业务方面的物流仓智能补货、门店智能订货、供应商协同辅助…'
        },
        {
            image:product1,
            title:'中石化智慧园区及智慧物流仓储平台',
            contect:'中国石化销售企业自2008年起涉足非油品领域，目前，已在全国加油站内开设易捷便利店2.3万家，成为便利店连锁行业内开店最多的品牌。山东省现有易捷便利店2000余家，2014年销售额已达17亿余元，全省在济南、青岛、潍坊、烟台、曲阜建立了5个物流中心仓，为易捷便利店提供配套的物流配送服务…'
        },{
            image:product3,
            title:'中节能万润精细化工物流仓储平台',
            contect:'中节能万润股份是国务院直属中节能集团下属的，从事液晶材料和其他精细化工产品研究、开发、生产和销售的高新技术上市企业，其产品具有技术含量高、生产难度大、高附加值等特点，企业有自营进出口权。已成为国内屈指可数的液晶材料生产厂家，形成了以液晶材料产品为主，光电化学产品、医药…'
        },
    ];

    // 左侧菜单跳转
    toPage(index:number) {
        let owner=this;
        owner.toptype=index;
        if(index===1){
            this.$router.push({
                path: "/home",
                query: {}
            });
        }else if(index===2){
            this.$router.push({
                path: "/companyprofile",
                query: {}
            });
        }else if(index===3){
            this.$router.push({
                path: "/product",
                query: {}
            });
        }else if(index===4){
            this.$router.push({
                path: "/news",
                query: {}
            });
        }else if(index===5){
            this.$router.push({
                path: "/mine",
                query: {}
            });
        }
    }
    // 左侧菜单跳转
    totab(index:number) {
        let owner=this;
        owner.topnumber=index;
        if(owner.topnumber===1){
            owner.pagetotle=13;
        }else if(owner.topnumber===2){
            owner.pagetotle=5;
        }else if(owner.topnumber===3){
            owner.pagetotle=5;
        }
    }
    inputpage(num:number){
        let owner=this;
        owner.page=num;
        console.log(this.page);
    }
    gotodetail(item:any){
        this.$router.push({
            path: `/productdetail${item.id}`,
            query: {
                // title:item.title
            }
        });
    }

    private created() {
        window.scrollTo(0, 0);
    }
}
